.profile-image {
    height: 170px;
    width: 170px;
    object-fit: cover;
    border: 2px solid #5FD1F9;
}

.subtitle {
    color: #898989;
    white-space: nowrap;
}

.twitter-user-details {
    padding: 10px;
    border-color: #F0F0F0;
    border-top: 2px solid #f0f0f0;
    border-bottom: 2px solid #f0f0f0;
    overflow-x: hidden;
}

.detail-right {
    border-left: 1px solid #f0f0f0;
}
.detail-left {
    border-right: 1px solid #f0f0f0;
}

.detail-centre {
    border-right: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
}
