.advanced-search-creation-container {
  /* background-color: white;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    border: 1px solid rgba(72, 94, 144, 0.16); */
  /* background-color:#E2EAF1; */
  padding: 15px;
}

.advanced-search-creation-container .title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.advanced-search-creation-widget {
  background-color: white;
  border: 1px solid rgba(72, 94, 144, 0.16);
  padding: 15px;
  font-size: 16px;
  border-radius: 0.35rem;
  height: 125px;
}

.advanced-search-creation-graph {
  background-color: white;
  border: 1px solid rgba(72, 94, 144, 0.16);
  padding: 15px;
  font-size: 16px;
  border-radius: 0.35rem;
}

.advanced-search-creation-graph.hidden{
  display: none;
}

.advanced-search-creation-expand-graph.hidden{
  display: none;
}

.advanced-search-creation-expand-graph {
  display: flex;
  justify-content: center;
  align-items: center;

}

.advanced-search-creation-filter-cloud {
  background-color: white;
  border: 1px solid rgba(72, 94, 144, 0.16);
  padding: 15px;
  font-size: 16px;
  border-radius: 0.35rem;
}

.advanced-search-creation-results {
  background-color: white;
  border: 1px solid rgba(72, 94, 144, 0.16);
  padding: 15px;
  font-size: 18px;
  border-radius: 0.35rem;
}

.advanced-search-counter {
  position: relative;
  min-height: 100px;
  height: 100%;
  font-weight: bold;
}

.advanced-search-counter span {
  text-align: center;
  font-size: 28px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.group {
  background: rgba(255, 255, 255, 0.5) !important;
  border: 2px solid #2f70c4 !important;
}

.rule {
  border: 2px solid rgba(72, 94, 144, 0.16) !important;
}

.ant-select-multiple .ant-select-selection-item {
  background-color: #2f70c4;
  color: white;
  border: 1px solid #2f70c4;
  border-radius: 0.35rem;
}

