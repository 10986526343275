#campaigns-manager-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: flex-start;
    /* align-content: flex-start; */
    margin-top: 15px;
    gap: 2em;
    padding-top: 2em;
}

#campaigns-manager-container .card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 20em;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: flex-start;
  /* align-content: flex-start; */
  /* margin-top: 15px;s */
  padding: 1.2em;
  justify-content: space-around;
}

#campaigns-manager-container .card .name {
  font-family: Avenir-Book, sans-serif;
  display: flex;
  margin-top: 1.2em;
  font-size: 1.2em;
  font-weight: 600;
  color: lightslategrey;
}

#campaigns-manager-container .card .date {
  display: flex;
  /* margin-top: 1.0em; */
  font-size: 1.0em;
  font-weight: 300;
  color: lightslategrey;

}

#campaigns-manager-container .card .status {
  display: flex;
  border: 2px solid;
  justify-content: center;
  font-size: 0.8em;
  color: #206FC3;
  padding: 0.5em;
  border-radius: 1.2em;
  font-weight: 500;
  /* background-color: #206FC3; */
  border-color: #206FC3;
}

#campaigns-manager-container .card .actions {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  justify-content: center;
}

#campaigns-manager-container .card .actions .button {
  height: 100%;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 700;
  border: 0px;
  background-color: #206FC3;
  color: white;
  padding: 0.4em;
}

#campaigns-manager-container .card .details {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  font-size: 0.7em;
  color: black;
  font-weight: 500;
  background-color: white;
  justify-content: center;
  align-items: center;
  
}

#campaigns-manager-container .card.create {
  border-width: 0.5em;
  border-color: #206FC3;
}