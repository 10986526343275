.csv-header-block {
    padding: 10px;
    border: 1px solid #D6D6D6 !important;
    border-radius: 5px;
    font-family: monospace;
    background-color: #f9f9f9;
}

.csv-download-button {
    text-decoration: none !important;
    color: white;
}

.csv-download-button:hover {
    text-decoration: none !important;
    color: white;
}

.csv-icon {
    color: white !important;
}