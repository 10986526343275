.organisation-contacts-container{
    background-color: white;
    padding: 0.5em;
}

.nav-tabs .nav-link {
    border: none !important;
}

.education-employment-tab{
    border-top: 1px solid #a7a7a7 !important;
    border-bottom: 1px solid #a7a7a7 !important;
}

.education-employment-tab .nav-link.active{
    border-bottom: 3px solid #4378f3 !important;
}

.contacts-employment-table table>   .th{
    font-weight: 300 !important;
}

.contacts-table-body{
    border-top: 1px solid #a7a7a7 !important;
}

.organisation-contact-details{
    flex-wrap: wrap;
    margin-right: 20px;
    flex-basis: 22.5%;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
}


.organisation-contact-discovered{
    display: flex;
    flex-basis: 12%;
    flex-grow: 0;
    flex-shrink: 0;
    align-content: center;
    align-items: center;
    text-align: center;
}

.organisation-contact-name{
    font-weight: 500;
    flex-wrap: wrap;
}

.organisation-contact-moreinfo-button{
    display: flex;
    align-items: center;
    flex-basis: 25%;
    flex-grow: 1;
    flex-shrink: 1;
}

.organisation-contact-social{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    flex-basis: 22.5%;
    flex-grow: 0;
    flex-shrink: 0;
    overflow-wrap: break-word;
    top: 50%;
    left: 50%;
}

.organisation-contact-company{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
    flex-basis: 18%;
    flex-grow: 0;
    flex-shrink: 0;
}

.company-logo{
    align-items: center;
}

.contacts-container .organisation-contact-row {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    /* align-items: center; */
    background-color: white;
    min-width: 300px;
    max-width: 100%;
    min-height: 105px;
}

.organisation-contact-contact{
    flex-basis: 22.5%;
    flex-grow: 0;
    flex-shrink: 0;
}

.organisation-contact-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #e2eaf2;
    box-shadow: 0 0 0 3px var(--very-light-blue);
    font-weight: 600;
    border-color: #377dc8;
    border-style: solid;
    color: black;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-self: center;
    margin: 15px;
    flex-basis: 4%;
    flex-grow: 0;
}
