.request-contacts-actions {
    display: flex;
    flex-direction: row;
    width: 100%
}

.sort-filter {
    display: flex;
    flex-direction: row-reverse;
    width: 80%;
}

.request-contacts {
    width: 20%;
}

.contacts-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    /* align-items: center; */
    flex-wrap: wrap;
}

.contacts-container .contact-container .contact-container-horizontal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
}

.contacts-container .contact-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    padding: 15px;
    background-color: white;
    min-width: 425px;
    max-width: 425px;
    min-height: 100px;
    border-radius: 15px;
    gap: 15px;
    padding-bottom: 50px;
}

.contacts-container .contact-container {
    height: 0%;
}

.contacts-container .contact-container .footer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    bottom: 0px;
    left: 0px;
}

.contacts-container .contact-container .tab {
    display: flex;
    justify-content: center;
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    background-color: #0079B5;
    color: white;
}

.contacts-container .contact-container .tab:hover {
    background-color: #0b496b;
    transition: background-color 200ms;
    cursor: pointer;
}

.contacts-container .contact-container .tab:first-child {
    border-bottom-left-radius: 15px;
}

.contacts-container .contact-container .tab:last-child {
    border-bottom-right-radius: 15px;
}

.contacts-container .contact-container .contact-container-horizontal .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 23px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: gray;
    box-shadow: 0 0 0 3px var(--very-light-blue);
    font-weight: 700;
    color: white;
    flex-shrink: 0;
}

.contacts-container .contact-container .contact-container-horizontal .identity {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
}

.contacts-container .contact-container .contact-container-horizontal .identity .name {
    font-family: Avenir-Book, sans-serif;
    display: flex;
    gap: 5px;
    font-size: 18px;
    font-weight: 600;
    color: #0079B5;
}

.contacts-container .contact-container .identity .job-title {
    font-size: 14px;
    color: rgba(111, 111, 111);
}

.contacts-container .contact-container .timeline {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow: auto;
    gap: 15px;
    /* background-color: teal; */
}

.contacts-container .contact-container .timeline .entry {
    display: flex;
    flex-direction: column;
    /* border: 1px solid; */
    /* border-color: lightgray; */
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
    gap: 2px;
}

.contacts-container .contact-container .timeline .entry .title{
    font-size: 16px;
    font-weight: 700;
}

.contacts-container .contact-container .timeline .entry .name{
    font-size: 14px;
    font-weight: 500;
}

.contacts-container .contact-container .timeline .entry .period{
    font-size: 12px;
    font-weight: 300;
}