.job-details{
    padding-bottom: 20px;
}

.job-role{
    display: block;
    height: 44px;
    color: #212121;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    text-align: center;
}

.job-card {
    transition: all 0.3s;
    width: 300px;
    height: auto;
    flex-shrink: 0;
    border-radius: 19px !important;
    border: 1px solid #cccccc;
    background-color: #ffffff;
}
.job-card-footer {
    background-color: #206fc3 !important;
    border-bottom-left-radius: 19px !important;
    border-bottom-right-radius: 19px !important;
    color: #FFF;
}

.job-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 2px 2px #93c1f2;
}

.job-footer {
    min-height: 56px !important;
    background-color: #206fc3 !important;
    border-bottom-left-radius: 19px !important;
    border-bottom-right-radius: 19px !important;
    color: #FFF;
    box-shadow: 3px 3px 3px #c5c5c5;
    position: absolute;
    bottom: -50px;
    width: 100%;
}


.job-advert-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
}

.job-company-name{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 270px;
    width: 100%;
    text-align: center;
    justify-content: center;
}
