.chart-hint {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 7px;
    border-radius: 2px;

    color: black;
    border: solid 1px #bdbdbd;
    border-radius: 2px;
    background-color: white;
    font-family: Roboto;
    transform: scale(1);
}

.chart-hint .x {
    font-size: 12px;
    font-weight: 700;
}

.chart-hint .y {
    font-size: 12px;
    font-weight: 500;
}

.ps-spend-filter-form {
    display: flex;
    justify-content: space-evenly;
    gap: 1em;
}

.ps-spend-filter-form .filter {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ps-spend-filter-form .filter .title {
    /* all: initial; */
    font-size: 0.7em;
}
