#status-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: flex-start;
    margin-top: 15px;
}

.status-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 60%;
    flex-direction: column;
    background-color: white;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.55rem !important;
    padding: 10px 15px 10px 15px;
    transition: box-shadow 0.5s;
    box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.12);
    flex-shrink: 1;
    flex-grow: 1;
    margin-bottom: 1em;
}

.status-card .header {
    display: flex;
    gap: 3em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.status-card .header .title {
}


.status-icon {
    border: 1px solid rgba(72, 94, 144, 0.5);
    border-radius: 11px !important;
    height: 22px;
    width: 22px;
}

.status-icon.red {
    background-color: #a10101;
}

.status-icon.orange {
    background-color: #ffa500;
}

.status-icon.green {
    background-color: #008000;
}

.service-indicator {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 1em;
    padding: 1em;
    width: 100%;
}

.instance-info {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    gap: 1em;
    padding: 1em;
    width: 100%;
}

.progress-container {
    width: 100%;
    height: 34px;
    border-radius: 41px;
    border: 1px solid #d6d6d6;
    background-color: #f8fcff;
}

.progress-bar {
    overflow: hidden;
    justify-content: center;
    text-align: center;
    padding-top: 3px;
    color: #FFF;
    white-space: nowrap;
    height: 22px;
    border-radius: 41px;
    font-size: 14px;
    background-image: linear-gradient(89deg, #206fc3 0%, #5fd1f9 100%);
    transition: width 1s ease;
}