.team-header-link {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 500
}

.team-header-link:hover {
    text-decoration: none;
    color: #ECECEC;
    font-weight: 500
}

.team-overview-label {
    font-size: 18px;
    letter-spacing: .5px;
    color: #FFFFFF;
    margin-bottom: 5px;
    transition: all 0.25s;
}

.team-overview-value {
    font-size: 30px;
    letter-spacing: .5px;
    color: #FFFFFF;
    margin-bottom: 5px;
    transition: all 0.25s;
}

.team-overview-list {
    color: #206fc3;
    font-size: 18px;
    font-weight: 500;
    padding-top: 5px;
}

.team-overview-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

.users-team-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-shrink: 0;
    gap: 40px;
    overflow: auto;
    padding: 10px;
}

.users-team-flex-container .profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.users-team-flex-container .profile .title{
    display: flex;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    text-transform: unset;
}

.team-activity-feed {
    list-style: none;
    padding-left: 15px;
    padding-top: 10px;
}

.team-activity-feed .item {
    position: relative;
    padding-bottom: 20px;
    padding-left: 35px;
    border-left: 2px solid #e4e8eb;
}

.team-activity-feed .item:last-child {
    border-color: transparent;
}

.team-activity-feed .avatar {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -25px;
    width: 50px;
    height: 50px;
}

.team-activity-feed .item .date {
    display: block;
    position: relative;
    top: -5px;
    color: #8c96a3;
    text-transform: uppercase;
    font-size: 15px;
}

.team-activity-feed .item .text {
    display: block;
    position: relative;
    top: -5px;
    color: black;
    text-transform: uppercase;
    font-size: 15px;
    text-transform: unset;
    
}
