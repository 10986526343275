.officer-interest {
    background-color: #009FDF;
    border: 1px solid #009FDF;
    color: #ffffff;
    border-radius: 12px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 2px;
}

