.update-company-title{
    text-align: center;
    font-weight: 500;
    padding: 20px;
    font-size: 16px;
    display:inline-block;
}

.fetch-container{
    display: flex;
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.error-message #text{
    padding-left: 5px;
}