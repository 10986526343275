#advanced-search-counter-wrapper {
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-left: 5px;
}

#advanced-search-counter {
  position: relative;
  display: block;
  height: auto;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #367bda;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.5rem;
  font-size: 44px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
}

.advanced-search-date {
  font-weight: 500;
  padding: 3px 5px 3px 5px;
  margin: 5px;
  background-clip: border-box;
  border: 2px solid white;
  border-radius: 0.35rem;
  color: white;
}

.advanced-search-company-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: white;
  min-height: 120px;
  margin: 15px;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.5rem;
  padding: 15px;
}

.advanced-search-company-container .left {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
}

.advanced-search-company-container .right {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: space-between;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: 1;
}

.advanced-search-company-container .company-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.7px;
  margin-bottom: 5px;
  transition: all 0.25s;
}

.advanced-search-company-container .company-introduction {
  margin-top: 5px;
  margin-bottom: 10px;
}

.advanced-search-company-container .company-number {
  position: absolute;
  display: block;
  font-size: 14px;
  font-weight: bold;
  bottom: 15px;
}

/* .advanced-search-company-container .company-actions {
    position: absolute;
    right: 15px;
} */
.advanced-search-company-container .company-button {
  position: relative;
  display: block;
  margin-right: 10px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 25px;
  border-width: 0px;
  border: 2px solid #ffffff;
  background-color: #e2eaf1;
  outline: none !important;
  min-width: 170px;
}

.advanced-search-company-container .company-button:hover {
  background-color: #e2eaf1;
  border: 2px solid #367bda;
}

@media (max-width: 650px) {
  .advanced-search-company-container .company-button {
    width: 100%;
  }
  .advanced-search-company-container .company-button #company-actions {
    margin-top: 25px !important;
    width: 100%;
  }
}

.advanced-search-company-container .company-button .icon {
  position: absolute;
  right: 7%;
  top: 30%;
  /* margin-left: 15px; */
}

.advanced-search-company-container .company-button .text {
  float: left;
}

/* .advanced-search-company-container .left .property {
  display: flex;
  justify-content: space-between;
  background-color: #48baf7;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.5rem;
    min-height: 25px;
  padding: 1px 4px 1px 4px;
}

.advanced-search-company-container .left .property .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
}

.advanced-search-company-container .left .property .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  color: white;
} */

.search-expired{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

#container-header .content {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 650px) {
  #container-header .content {
    flex-wrap: wrap;
  }
  #advanced-search-counter-wrapper {
    width: 100%;
  }
}

.advanced-search-company-container .search-properties {
  display: flex;
}

.advanced-search-company-container .property {
  display: flex;
  justify-content: space-between;
  background-color: #EFEFEF;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.5rem;
  min-height: 25px;
  padding: 1px 4px 1px 4px;
  margin-right: 10px;
}

.advanced-search-company-container .property .icon {
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
}

.advanced-search-company-container .property .text {
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  color: black;
}

.advanced-search-buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.advanced-search-buttons-add-to-list {
  margin-top: 3px;
}

.advanced-search-buttons-page-selector {
  padding-top: 8px;
}

.advanced-search-buttons-spacer {
  width: auto;
  display: flex;
  flex: 1;
}
