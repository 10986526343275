.organisation-company-structure {
    display: flex;
    flex-direction: row;
    border-radius: 0.5rem;
    min-height: 25px;
    margin-top: 10px;
    margin-right: 10px;
    padding-left: 15px;
}


.company-structure-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.company-structure-container .item{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.company-structure-container .item .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    min-width: 25px;
    border-radius: 0.5rem;
    background-color: #3078C7;
    height: 35px;
    width: 35px;
    flex-shrink: 0;
}

.company-structure-container .item .text{
    display: flex;
    justify-items: flex-start;
    align-items: center;
    font-weight: 500;
    /* flex: 1 0 0px; */
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px;
}

