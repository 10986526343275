.flex-row {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.flex-row.gap-s{
    gap: 0.4em;
}

.flex-col {
    display: flex;
    flex-direction: column;
    gap: 1.3em;
}

.flex-col.gap-s{
    gap: 0.4em;
}



.header-card {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
}

.inner-card {
    font-weight: normal;
    font-size: 0.7em;
}

.test {
    vertical-align: bottom;
}

.isin-price {
    font-size: 2.4em;
    font-weight: normal;
}

.justify-center {
    justify-content: center;
}

.disabled-card {
    opacity: 0.5;
}

.w-full {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.postive-percent {
    color: lightgreen;
}

.negative-percent {
    color: indianred;
}

.w-25 {
    width: 25%;
}

.w-15 {
    width: 15%;
}

.item {
    padding: 0.5em;
}

.justify-end {
    justify-content: flex-end;
}

.align-center {
    align-items: center;
}

.text-center {
    text-align: center;
}