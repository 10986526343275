.request-contacts-form
  .ant-select-multiple.ant-select-sm
  .ant-select-selection-search {
  height: initial;
  
}

.request-contacts-form
  .ant-select-multiple.ant-select-sm
  .ant-select-selection-search {
  height: 25px;
}

.request-contacts-form .ant-select-multiple .ant-select-selection-item {
  background-color: #2f70c4;
  color: white;
  border: 1px solid #2f70c4;
  border-radius: 0.35rem;
  /* font-size: 16px; */
  font-weight: 500;
}

.export-toggle::after {
  display: none !important;
}

.input-modal-box {
  min-width: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  line-height: 1.5715;
  width: 100%;
  font-size: 16px;
  color: black;
  padding: 7px 10px;
  box-sizing: border-box;
  outline: none;
}

.input-modal-box:focus {
  border: 1px solid #40A9FF;
  box-shadow: 0px 0px 3px 0px #40A9FF;
}

.input-modal-box::placeholder {
  color: #cccccc;
}