
#adv-search-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: flex-start;
  margin-top: 15px;
}

.adv-search-card {
  position: relative;
  display: flex;
  align-self: flex-start;
  width: 700px;
  justify-content: space-between;
  min-width: 350px;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.55rem !important;
  padding: 10px 15px 0px 15px;
  transition: box-shadow 0.5s;
  flex-shrink: 1;
  flex-grow: 1;
  /*animation: fadein 1s;*/
}

.adv-search-card.expired-card {
  background-color: #F4F7FA;
}

.adv-search-card > .expired {
  opacity: 0.7;
}

@media (min-width: 1700px) {
  .adv-search-card {
    width: 750px;
    flex-grow: 0;
    margin: 15px;
  }
  #adv-search-list-container {
    justify-content: space-between;
  }
}

@media (max-width: 1700px) {
  .adv-search-card {
    max-width: auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.adv-search-card:hover {
  box-shadow: 0 0 2px 2px #93c1f2;
  transition: box-shadow 0.5s;
}

.adv-search-magnifier-box {
  position: relative;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.55rem;
  height: 35px;
  width: 35px;
  background-color: #3078c7;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.adv-search-card .name {
  font-family: Avenir-Book, sans-serif;
  position: relative;
  margin-left: 12px;
  top: 2px;
  font-size: 22px;
  font-weight: 600;

  color: #3078c7;
  text-transform: uppercase;
  border-radius: 0.35rem;
  text-decoration: none;
}

.adv-search-content-container {
  position: relative;
  margin-top: 7px;
  margin-left: -5px;
}

.adv-search-content {
  display: flex;
  flex-direction: column;
}

.adv-search-details-container {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
}

.adv-search-detail-container {
  display: flex;
  justify-content: space-around;
}

.adv-search-detail {
  /* font-weight: bold; */
  padding: 4px;
  margin: 5px;
  background-clip: border-box;
  /* border: 1px solid rgba(72, 94, 144, 0.16); */
  border-radius: 0.35rem;
  color: black;
  background-color: #efefef;
}

.adv-search-actions {
  display: flex;
  justify-content: flex-end;
  align-content: space-around;
  flex-wrap: wrap;
  margin-top: 15px;
}

.adv-search-action {
  margin: 5px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.adv-search-shared-with {
  position: relative;
  bottom: -0.8rem;
  display: flex;
  background-color: #ffffff;
  font-family: Roboto;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0.4rem 0.8rem 0.4rem 0.8rem;
  margin-left: -1rem;
  margin-right: -1rem;
  width: auto;
  font-size: 0.8rem;
  color: #212529;
  box-shadow: inset 0px 5px 8px rgba(0,0,0,0.2);
  gap: 0.2rem;
  flex-wrap: wrap;
}

.adv-search-shared-with .team {
  font-weight: 500;
  background-color: #efefef;
  color: #212529;
  padding-left: 0.4rem;
  padding-right: 0.4em;
  border-radius: 0.5rem;
}
