.csv-header-block {
    border: 1px solid #D6D6D6 !important;
    border-radius: 5px;
    font-family: monospace;
    background-color: #f9f9f9;
}

.sub-title {
    font-size: 0.75em;
}
.details-title {
    font-size: 0.60em;
}
.br {
    display: block;
    margin-bottom: 0em;
}

.preserve-space{
    white-space: break-spaces;
}

.csv-download-button {
    text-decoration: none !important;
    color: white;
}

.csv-download-button:hover {
    text-decoration: none !important;
    color: white;
}

.csv-icon {
    color: white !important;
}