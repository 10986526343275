.profile-integration-container{
    display: flex;
    flex-wrap: wrap;
}

.profile-integration-container .item{
    flex: 1 0 35%;
    margin: 10px;
}


.profile-integration-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    height: 100%;
}

.profile-integration-card .title {
    display: flex;
    gap: 1em;
    align-items: center;
    margin-bottom: 0.5em;
    justify-content: center;
}

.token-expiry{
    font-size: 15px;
    margin: 0 auto;
    padding: 10px;
    font-weight: 500;
}

.integration-status-green{
    height: 15px;
    width: 15px;
    background-color: #027907;
    border-radius: 50%;
    margin-left: auto;
    margin-top: auto;
}

.integration-status-orange{
    height: 15px;
    width: 15px;
    background-color: #ff6b00;
    border-radius: 50%;
    margin-left: auto;
    margin-top: auto;
}

.integration-status-red{
    height: 15px;
    width: 15px;
    background-color: #ff0000;
    border-radius: 50%;
    margin-left: auto;
    margin-top: auto;
}