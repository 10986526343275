#campaigns-manager-editor {
  display: flex;
  margin-top: 2em;
  flex-direction: column;
  background-color: white;
  padding: 2em;
}

#campaigns-manager-editor #title {
  font-size: 2em;
  
}


#campaigns-manager-editor .campaign-input-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

#campaigns-manager-editor .campaign-input {
  display: flex;
  gap: 2em;
}

#campaigns-manager-editor .children {
  padding: 1.2em;
  background-color:cornsilk;
}