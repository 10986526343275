.organisation-details-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.organisation-details-markup {
    display: flex;
    border-radius: 0.5rem;
    min-height: 25px;
    margin-top: 10px;
    margin-right: 10px;
    padding-left: 15px;
}

.organisation-details-markup .left {
    display: flex;
    justify-items: flex-start;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 500;
    /* flex: 1 0 0px; */
    flex-grow: 1;
    flex-shrink: 0;
}

.organisation-details-markup .left .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    min-width: 25px;
    border-radius: 0.5rem;
    background-color: #3078C7;
    height: 35px;
    width: 35px;
    flex-shrink: 0;
}

.organisation-details-markup .text {
    padding: 10px;
}

.organisation-details-markup .right {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 300;
    text-transform: uppercase;
    transition: all 0.25s;
    text-align: right;
}
