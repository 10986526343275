.officer-card {
    width: 300px;
    flex-shrink: 0;
    height: 310px;
    border-top-left-radius: 19px !important;
    border-top-right-radius: 19px !important;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    box-shadow: 3px 3px 3px #c5c5c5;
}

.officer-title {
    width: 191px;
    height: 44px;
    color: #212121;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
}

.officer-title:hover {
    color: #1f52a0;
    text-decoration: none;
}

.officer-occupation {
    width: 226px;
    height: 22px;
    color: #212121;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.officer-footer {
    min-height: 56px !important;
    background-color: #206fc3 !important;
    border-bottom-left-radius: 19px !important;
    border-bottom-right-radius: 19px !important;
    color: #FFF;
    box-shadow: 3px 3px 3px #c5c5c5;
    position: absolute;
    bottom: -50px;
    width: 100%;
}

.officer-footer-link {
    color: #FFFFFF !important;
}

.officer-footer-link:hover {
    color: #c5c5c5 !important;
}
