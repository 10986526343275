#navbar-search {
    width: 100%;
}

#navbar-search #navbar-search-history {
    display: flex;
    height: 100%;
    padding: 5px;
    position: absolute;
    top: 0px;
    right: 90px;
}

#navbar-search #navbar-search-filter {
    display: flex;
    height: 100%;
    padding: 5px;
    position: absolute;
    top: 0px;
    right: 15px;
    z-index: 999;
}

#navbar-search #navbar-search-filter .ant-select-multiple .ant-select-selection-item {
    background-color: whitesmoke;
    color: black;
    border: 1px solid whitesmoke;
    border-radius: 0.35rem;
    /* font-size: 16px; */
    font-weight: 700;
}

#navbar-search #navbar-search-filter .ant-select-multiple .ant-select-selection-item-remove {
    display: flex;
    justify-content: center;
}


.nav-filters-button {
    background-color: white !important;
    color: #3378D1 !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 12px !important;
}

.nav-filters-button:hover {
    background-color: #3378D1 !important;
    color: white !important;
}

.navbar-nav{
    flex-direction: row!important;
    padding-top: 0px;
}

@media (min-width: 320px) {
    #nav-bar-action-buttons{
        flex-direction: row;
        padding-top: 10px;
    }
}

@media (min-width: 570px) {
    #nav-bar-action-buttons{
        flex-direction: row;
        padding-top: 0;
    }
}
