#contracts-list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: flex-start;
    margin-top: 15px;
}

.contract-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 700px;
    flex-direction: column;
    background-color: white;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.55rem !important;
    padding: 10px 15px 0px 15px;
    /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.12); */
    transition: box-shadow 0.5s;
    flex-shrink: 1;
    flex-grow: 1;
    animation: fadein 1s;
}

.contract-link-container {
    width: 700px;
}

@media (min-width: 1700px) {
    .contract-card {
        flex-grow: 0;
        margin: 15px;
    }
    .contract-link-container {
        width: 750px;
    }
    #contracts-list-container {
        justify-content: space-between;
    }
}

@media (max-width: 1700px) {
    .contract-card {
        max-width: auto;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
.contract-card:hover {
    box-shadow: 0 0 2px 2px #93c1f2;
    transition: box-shadow 0.5s;
}

.contract-icon-box {
    position: relative;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.55rem;
    height: 35px;
    width: 35px;
    background-color: #3078c7;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.contract-card .name {
    font-family: Avenir-Book, sans-serif;
    position: relative;
    margin-left: 12px;
    top: 2px;
    font-size: 22px;
    font-weight: 600;
    background-color: white;
    color: #3078c7;
    text-transform: uppercase;
    border-radius: 0.35rem;
}

.contract-content-container {
    position: relative;
    margin-top: 7px;
    margin-left: -5px;
}

.contract-content {
    display: flex;
    flex-direction: column;
}

.contract-details-container {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
}

.contract-detail-container {
    display: flex;
    justify-content: space-around;
}

.contract-detail {
    /* font-weight: bold; */
    padding: 4px;
    margin: 5px;
    background-clip: border-box;
    /* border: 1px solid rgba(72, 94, 144, 0.16); */
    border-radius: 0.35rem;
    color: black;
    background-color: #efefef;
}

.contract-actions {
    display: flex;
    justify-content: flex-end;
    align-content: space-around;
    flex-wrap: wrap;
    margin-top: 15px;
}

.contract-action {
    margin: 5px;
}


.row-selected {
    background-color: rgba(34, 150, 243, 0.4) !important;
}

.ribbon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.contract-properties-container {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
}


.contract-property-container {
    display: flex;
    justify-content: space-around;
}

.contract-property {
    font-weight: 500;
    padding: 3px 5px 3px 5px;
    margin: 5px;
    background-clip: border-box;
    border: 2px solid white;
    border-radius: 0.35rem;
}

.contract-property-container > .purple {
    color: white;
    border: 1px solid #9120C3;
    background-color: #9120C3;

}

.contract-property-container > .purple-inverted {
    color: #9120C3;
    border: 1px solid #9120C3;
    background-color: white;

}

.contract-property-container > .dark-pink {
    color: white;
    border: 1px solid #f84899;
    background-color: #f84899;
}

.contract-property-container > .dark-pink-inverted {
    color: #f84899;
    border: 1px solid #f84899;
    background-color: white;
}

.contract-property-container > .green {
    color: white;
    border: 1px solid #30C4A2;
    background-color: #30C4A2;
}

.contract-property-container > .green-inverted {
    color: #30C4A2;
    border: 1px solid #30C4A2;
    background-color: white;
}

.pagination {
    display: flex;
    flex-direction: row;
}
