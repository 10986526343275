#login-container {
    height: 100%;
    display: flex;
}

#login-form-container {
    border-radius: 15px;
    margin: auto;
    min-height: 550px;
    max-width: 600px;
    background-color: white;
}

#login-logo {
    display: block;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 60px;
}

.center{
    justify-content: center;
}

#login-form-header {
    text-align: center;
    width: 100%;
}

#login-form-header .title {
    color: #2F6FC3;
    font-size: 35px;
    margin-top: 15px;
}

#login-form-header .description {
    color: #898989;
    font-size: 22px;
    margin-top: 5px;
}

#login-logo img {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    /* height: 85px; */
}

#login-form {
    margin-top: 45px;
    margin-left: 77px;
    margin-right: 77px;
}

#login-container button {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 20px;
    border-radius: 25px;
    border-width: 0px;
    text-align: center;
    height: 52px;
    font-size: 18px;
    font-weight: bold;
    background: linear-gradient(90deg, rgba(54, 123, 218, 1) 0%, rgba(72, 186, 247, 1) 100%);
    font-family: "Avenir-Book", sans-serif;
    font-weight: 600;
    width: 100%;
}

.login-input {
    height: 50px !important;
    font-family: Avenir-Book, sans-serif;
}

#login-form .remember-me {
    float:right;
}

#login-form .forgot-password {
    font-weight: bold;
    cursor: pointer;
}
