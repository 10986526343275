.iq-stock-price-container {
    width: 100%;
    height: 22px;
    /*border-radius: 41px;*/
    border: 1px solid #d6d6d6;
    background-color: #f8fcff;
    border-right-width: 3px;
    border-right-color: black;

    border-left-width: 3px;
    border-left-color: black;

}

.iq-stock-price-bar {
    overflow: hidden;
    justify-content: center;
    text-align: center;
    padding-top: 6px;
    color: #FFF;
    white-space: nowrap;
    width: 20px;
    height: 20px;
    border-radius: 41px;
    background-color: #206fc3;
    transition: width 1s ease;
}

.iq-stock-price-bar-text {
    color: #206fc3;
}

.iq-stock-price-mini-text {

    color: dimgrey;

}

