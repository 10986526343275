.iq-progress-container {
    width: 100%;
    height: 34px;
    border-radius: 41px;
    border: 1px solid #d6d6d6;
    background-color: #f8fcff;
}

.iq-progress-bar {
    overflow: hidden;
    justify-content: center;
    text-align: center;
    padding-top: 6px;
    color: #FFF;
    white-space: nowrap;
    height: 32px;
    border-radius: 41px;
    background-image: linear-gradient(89deg, #206fc3 0%, #5fd1f9 100%);
    transition: width 1s ease;
}
