.list-navigation-link {
    color: #ffffff;
}

.list-navigation-link:hover {
    flex-shrink: 0;
    color: white;
}

.list-navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-navigation-container a:hover {
   text-decoration: white !important;
}

.list-navigation-container .arrow-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:160px;
    height: 60px;
    background:#377DDB;
    margin-left:15px;
    position:relative;
    border-top-right-radius: 5%;
    border-bottom-right-radius: 5%;
}

.list-navigation-container .arrow-left:before {
    content:"";
    position:absolute;
    border-bottom: 30px solid transparent;
    border-right: 20px solid #377DDB;
    border-top: 30px solid transparent;
    height: 0px;
    width: 0px;
    margin-left:-20px;
}

.list-navigation-container .arrow-left .text{
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    padding: 10px;
}

.list-navigation-container .arrow-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:160px;
    height: 60px;
    background:#48B9F7;
    margin-right:20px;
    position:relative;
    border-top-left-radius: 5%;
    border-bottom-left-radius: 5%;
}
.list-navigation-container .arrow-right:after {
    content:"";
    position:absolute;
    border-bottom: 30px solid transparent;
    border-left: 20px solid #48B9F7;
    border-top: 30px solid transparent;
    margin-left: 160px;
}

.list-navigation-container .arrow-right .text{
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    padding: 15px;
}