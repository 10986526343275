.filing-history-widget-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    padding: 15px;
    flex-grow: 1;
}

.filing-history-widget-container .entry {
    display: flex;
    align-items: center;
    height: auto;
    border: 1px solid;
    border-color: lightgray;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 15px;
}

.filing-history-widget-container .entry .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.filing-history-widget-container .entry .content .description {
    font-size: 14px;
    font-weight: 500;
}

.filing-history-widget-container .entry .content .date-published {
    font-size: 14px;
    font-style: italic;
}