.flex-row {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.item {
    padding: 0.5em;
}

.justify-end {
    justify-content: flex-end;
}

.align-center {
    align-items: center;
}

.change-indicator {
    position: relative;
    min-width: 0;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    height: 100%;
}

.positive {
    color: #51c768;
}

.negative {
    color: #bd3636;
}

.variance {
    font-weight: bold;
}