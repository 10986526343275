.dashboard-contract-title-label {
    position: relative;
    margin-left: 12px;
    top: 2px;
    font-size: 16px !important;
    font-weight: 500;
    background-color: white;
    color: #3078c7 !important;
    text-transform: uppercase;
    border-radius: 0.35rem;
    cursor: pointer;
}

.dashboard-contract-end {
    margin-left: 5px;
    margin-right: 5px;
}

.pink {
    color: #f84899 !important;
}

.purple {
    color: #9120C3 !important;
}

.dashboard-contract-details-container {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
    height: 32px;
    margin-bottom: 10px;
}

.dashboard-contract-detail-container {
    display: flex;
    justify-content: space-around;
}

.dashboard-contract-detail {
    padding-top: 3px;
    background-clip: border-box;
    border-radius: 0.35rem;
    color: black;
    background-color: #efefef;
    height: 32px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: default;
}

.unfollow-button {
     max-width: 120px;
 }
