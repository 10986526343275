.filter-box {
    background-color: white;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
}

.filter-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    width: 100%;
    padding: 20px;
    height: 100%;
}

.filter-button {
    position: relative;
    margin-right: 10px;
    height: 40px;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 25px;
    border-width: 0px;
    border: 2px solid #3179C7;
    color: #212121;
    background-color: #ffffff;
    outline: none !important;
    width: 170px;
}

.filter-button:hover:not(:disabled) {
    background-color: lightgray;
    border: 2px solid black;
}

.filter-button:disabled {
    opacity: 50%;
    cursor: not-allowed;
}

.filter-button .icon {
    position: absolute;
    right: 7%;
    top: 30%;
    /* margin-left: 15px; */
}

.filter-button .text {
    float: left;
}

#techstack-category:hover{
    text-decoration: underline;
}