.badge-link {
    margin-right: 10px;
    font-size: 14px !important;
    padding: 7px !important;
    background-color: #3a9fdf !important;
    color: #FFF !important;
    margin-bottom: 5px;
}

.badge-link:hover {
    background-color: #367fbb !important;
    cursor: pointer;
}

#accreditation-action {
    min-width: 250px;
    padding: 20px;
}