.terms-agreement-container {
    background-color: white;
    height: 100%;
    padding: 15px;
    overflow: scroll;
}

.terms-agreement-content {
    display: block;
    height: 90%;
    margin-left: 15px;
    margin-right: 15px;
    border: 5px solid rgba(72, 94, 144, 0.16); 
    padding: 15px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.terms-agreement-content::-webkit-scrollbar {
    width: 15px;
}

.terms-agreement-content::-webkit-scrollbar-thumb {
    height: 15%;
}

.terms-agreement-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    width: 100%;
}

.terms-agreement-actions button {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 20px;
    border-radius: 25px;
    border-width: 0px;
    text-align: center;
    height: 52px;
    font-size: 18px;
    font-weight: bold;
    background: linear-gradient(90deg, rgba(54, 123, 218, 1) 0%, rgba(72, 186, 247, 1) 100%);
}

.terms-agreement-container .introduction {
    justify-content: center;
    text-align: center;
    margin: auto auto 10px;
}

.terms-agreement-container .introduction-heading {
    margin-top: 20px;
}

.terms-agreement-container .welcome-logo {
    height: 50px;
}

.terms-text {
    display: inline;
    color: #2f6fc3;
    text-decoration: underline;
}

.terms-text:hover {
    color: #0b40ff;
    cursor: pointer;
}

.terms-agreement-check-text {
    justify-content: center;
    text-align: center;
}

.shrink-terms-agreement {
    height: 0;
    overflow: hidden;
    transition: ease-out 500ms;
}

@media only screen and (max-width: 550px) {
     .grow-terms-agreement {
        height: 60%;
    }

    .terms-agreement-content {
        padding: 5px;
    }
}

@media only screen and (min-width: 550px) {
    .grow-terms-agreement {
        height: 70%;
    }
}

.grow-terms-agreement {
    transition: ease-out 500ms;
}
