.filter-bubble {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 3px solid #3b97e8;
  background-color: #3b97e8;
  border-radius: 25px;
  height: 45px;
  margin-right: 15px;
  padding-top: 5px;
  padding-left: 2px;
  padding-bottom: 5px;
  padding-right: 15px;
  min-width: 120px;
  cursor: pointer;
  margin-bottom: 15px;
  transition: background-color 1s, border 1s, margin-right 1s;
}

.filter-bubble.active {
  border: 3px solid #2f6fc4;
  background-color: #2f6fc4;
  cursor: default;
  transition: background-color 1s, border 1s, margin-right 1s, height 1s;
  padding-right: 20px;
  margin-right: 20px;
}

.filter-bubble.editable {
  background-color: #ff8c00;
  border: 3px solid #ff8c00;
  min-height: 75px;
  height: auto;
  cursor: default;
  margin-right: 70px;
  transition: height 1s, background-color 1s, border 1s, margin-right 1s;
  padding-right: 20px;
}

.filter-bubble.editable .input {
  font-size: 12px;
  margin-top: 4px;
}

.filter-bubble .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  left: 5px;
  border: 2px solid white;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
  color: white;
  width: 37px;
  height: 37px;
  flex-shrink: 0;
}

.filter-bubble .content {
  display: flex;
  flex-direction: column;
  color: white;
}

.filter-bubble .content .title {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.filter-bubble .content .selection {
  font-size: 12px;
}

.filter-bubble.active .remove {
  position: absolute;
  right: -15px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  background-color: grey;
  border-radius: 50%;
  padding: 7px;
  color: white;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.filter-bubble.editable .apply {
  position: absolute;
  right: -25px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  background-color: #b36200;
  border-radius: 50%;
  padding: 7px;
  color: white;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.filter-bubble.editable .remove {
  position: absolute;
  right: -60px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  background-color: grey;
  border-radius: 50%;
  padding: 7px;
  color: white;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.filter-bubble.editable input {
  max-width: 200px;
}

.filter-bubble.editable select {
  max-width: 200px;
  min-width: 150px;
  color: black;
  height: 35px;
  font-size: 14px;
  font-weight: bold;
  transition: width 1s;
  background-color: white;
  border: 2px solid #b36200;
  border-radius: 15px;
  padding: 2px;
  outline: none;
}

.filter-bubble-overriden-ant-select-dropdown {
  min-width: 200px !important;
  width: 600px !important;
  max-width: auto !important;
}

.filter-bubble .ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 30px;
  line-height: 14px;
  font-weight: bold;
  font-size: 0.9em;
  word-wrap: break-word;
}

.filter-bubble .ant-select-multiple .ant-select-selection-item {
  background-color: #3378D1;
  color: white;
  border: #3378D1;
  border-radius: 0.35rem;
  padding: 8px;
}

.filter-bubble .ant-select-selection-item-remove {
  color: white !important;
  
}