.cs-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.cs-grid-column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}