#lists-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: flex-start;
  margin-top: 15px;
}

.list-card {
  position: relative;
  display: flex;
  width: 750px;
  justify-content: space-between;
  min-width: 350px;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.55rem !important;
  padding: 10px 15px 0px 15px;
  transition: box-shadow 0.5s;
  flex-shrink: 1;
  flex-grow: 1;
  animation: fadein 1s;
}

.list-link-container {
  max-width: 750px;
}

@media (min-width: 1700px) {
  .list-card {
    flex-grow: 0;
    margin: 15px;
  }
  .list-link-container {
    width: 750px;
  }
  #lists-list-container {
    justify-content: space-between;
  }
}

@media (max-width: 1700px) {
  .list-card {
    max-width: inherit;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.list-card:hover {
  box-shadow: 0 0 2px 2px #93c1f2;
  transition: box-shadow 0.5s;
}

.list-icon-box {
  position: relative;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.55rem;
  height: 35px;
  width: 35px;
  background-color: #3078c7;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.list-card .name {
  font-family: Avenir-Book, sans-serif;
  font-weight: 600;
  position: relative;
  margin-left: 12px;
  margin-right: 12px;
  top: 2px;
  font-size: 22px;
  background-color: white;
  color: #3078c7;
  text-transform: uppercase;
  border-radius: 0.35rem;
}

.list-content-container {
  position: relative;
  margin-top: 7px;
  margin-left: -5px;
}

.list-content {
  display: flex;
  flex-direction: column;
}

.list-details-container {
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
}

.list-detail-container {
  display: flex;
  justify-content: space-around;
}

.list-detail {
  /* font-weight: bold; */
  padding: 4px;
  margin: 5px;
  background-clip: border-box;
  /* border: 1px solid rgba(72, 94, 144, 0.16); */
  border-radius: 0.35rem;
  color: black;
  background-color: #efefef;
}

.list-actions {
  display: flex;
  justify-content: flex-end;
  align-content: space-around;
  flex-wrap: wrap;
  margin-top: 15px;
}

.list-action {
  margin: 5px;
}


.row-selected > td {
  background-color: rgba(34, 150, 243, 0) !important;
}

.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
}

/* .ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #3078c7;
} */

.ribbon-orange span {
  background-color: #ff7e4e;
}

.ribbon-blue span {
  background-color: #3078c7;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 150px;
  padding: 15px 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 14px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: 0px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -5px;
  top: 10px;
  transform: rotate(45deg);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.list-properties-container {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
}


.list-property-container {
    display: flex;
    justify-content: space-around;
}

.list-property {
    font-weight: 500;
    padding: 3px 5px 3px 5px;
    margin: 5px;
    background-clip: border-box;
    border: 2px solid white;
    border-radius: 0.35rem;
}

.list-property-container > .purple {
    color: white;
    border: 1px solid #9120C3;
    background-color: #9120C3;

}

.list-property-container > .purple-inverted {
    color: #9120C3;
    border: 1px solid #9120C3;
    background-color: white;

}

.list-property-container > .dark-pink {
    color: white;
    border: 1px solid #f84899;
    background-color: #f84899;
}

.list-property-container > .dark-pink-inverted {
    color: #f84899;
    border: 1px solid #f84899;
    background-color: white;
}

.list-property-container > .green {
    color: white;
    border: 1px solid #30C4A2;
    background-color: #30C4A2;
}

.list-property-container > .green-inverted {
    color: #30C4A2;
    border: 1px solid #30C4A2;
    background-color: white;
}

.pagination {
    display: flex;
    flex-direction: row;
}

.list-shared-with {
  position: relative;
  bottom: 0rem;
  display: flex;
  background-color: #ffffff;
  font-family: Roboto;
  border-bottom-left-radius: 0.55rem !important;
  border-bottom-right-radius: 0.55rem !important;
  padding: 0.4rem 0.8rem 0.4rem 0.8rem;
  margin-left: -1rem;
  margin-right: -1rem;
  width: auto;
  font-size: 0.8rem;
  color: #212529;
  box-shadow: inset 0px 5px 8px rgba(0,0,0,0.2);
  gap: 0.2rem;
  flex-wrap: wrap;
}

.list-shared-with .team {
  font-weight: 500;
  background-color: #efefef;
  color: #212529;
  padding-left: 0.4rem;
  padding-right: 0.4em;
  border-radius: 0.55rem !important;
}
