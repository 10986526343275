.ccs-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.7px;
    color:  #2f6fc3;
    margin-bottom: 5px;
    transition: all 0.25s;
}

.ccs-subtitle {
    color: #898989;
}

.ccs-main {
    display: flex;
    flex-direction: row;
    gap: 4%;
}

.ccs-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2%;
    margin-bottom: 1em;
}

.ccs-detail-item {
    width: 33%;
}

.detail-label {
    font-weight: 550;
}