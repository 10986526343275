.renewals-dashboard-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
    margin-right: 10px;
    overflow: hidden;
    gap: 2em;
    margin-top: 10px;
}

.renewals-dashboard-item {
    flex-grow: 1;
    min-width: 40%
}