.remove-member {
    color: #ed3833 !important;
}

.remove-member:hover {
    color: #ca302d !important;
}

.add-member {
    color: #5cc531 !important;
}

.add-member:hover {
    color: #52a22e !important;
}
