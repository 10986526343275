#company-summary-container {
  display: inline-flex;
  align-items: center;
}

#company-summary {
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

#company-actions {
  /* margin-left: auto; */
  float: right;
  /* min-width: 244px; */
}

@media (max-width: 1200px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  #company-summary {
    display: block;
    /* margin-left: auto;
    margin-right: auto; */
  }
}

.organisation-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.35rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  height: 100%;
}

.organisation-card.purple {
  border-radius: 5px;
  background-color: #9120c3;
  background-image: url(../../../public/search.svg);
  background-repeat: no-repeat;
  background-position: 103% -80%;
  background-size: 140px 140px;
}

.organisation-card.pink {
  border-radius: 5px;
  background-color: #f84899;
  background-image: url(../../../public/visits.svg);
  background-repeat: no-repeat;
  background-position: 110% -175%;
  background-size: 134px 134px;
}

.organisation-card.green {
  border-radius: 5px;
  background-color: #30c4a2;
  background-image: url(../../../public/person-star.svg);
  background-repeat: no-repeat;
  background-position: 133% 25%;
  background-size: 208px 198px;
}

.organisation-card.orange {
  border-radius: 5px;
  background-color: #ff7e4e;
  background-image: url(../../../public/checklist.svg);
  background-repeat: no-repeat;
  background-position: 110% -40%;
  background-size: 124px 157px;
}

.organisation-card-text {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 300;
}

.centerize {
  margin: 0 auto;
  width: 600px;
}

.iq-tx-11 {
  font-size: 11px;
}

.iq-tx-14 {
  font-size: 14px;
}

.iq-tx-12 {
  font-size: 12px;
}

.iq-tx-uppercase {
  text-transform: uppercase;
}

.iq-tx-spacing-1 {
  letter-spacing: 0.5px;
}

.iq-tx-semibold {
  font-weight: 600;
}

.iq-tx-color-02 {
  color: #1b2e4b;
}

.iq-lh-1 {
  line-height: 1.1;
}

.iq-tx-rubik {
  font-family: "Rubik", sans-serif;
}

.iq-tx-normal {
  font-weight: 400;
}

.iq-tx-medium {
  font-weight: 500;
}

.iq-mg-r-5 {
  margin-right: 5px;
}

.iq-mg-b-0 {
  margin-bottom: 0px;
}

.iq-h3 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.25;
  color: #001737;
}

.iq-h4 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.25;
  color: #001737;
}

.growth-positive {
  color: #51c768;
  background-color: #cbffd5;
}

.growth-negative {
  color: #bd3636;
  background-color: #faa3a3;
}

.growth-text {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.35rem;
  padding: 5px;
}

.badge-tag {
  margin-right: 10px;
  font-size: 14px !important;
  padding: 7px !important;
  background-color: #3a9fdf !important;
  margin-bottom: 5px;
}

.organisation-card-label {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  color: #929292;
  margin-bottom: 5px;
  transition: all 0.25s;
  font-family: "Avenir-Book", sans-serif;
  font-weight: 600;
}

.graph-data-missing {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.organisation-social-handles {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.organisation-social-handle-y-axis {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.organisation-card .classification-overview-label {
  font-size: 18px;
  letter-spacing: .5px;
  color: white;
  margin-bottom: 5px;
  transition: all 0.25s;
}

.organisation-card .classification-overview-value {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: .5px;
  color: black;
  margin-bottom: 5px;
  transition: all 0.25s;
}

.organisation-card.nosvg {
  background-image: none;
}

.organisation-card-label.lightgrey{
  color: gainsboro;
}
.organisation-card-label .selected {
  color:  #2f6fc3;
}

.unbulleted-list {
  list-style-type: none;
}

.public-sector-contract-row {
}

.public-sector-contract-column {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}