.renewal-info {
    display: flex;
    flex-direction: row;
    gap: 2%;
}

.info-item {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.agreement-info-item {
    width: 33%;
    display: flex;
    flex-direction: column;
}

.info-item-full {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.item-headline {
    color:  #2f6fc3;
    text-transform: uppercase;
    margin-botton: 2em;
}

.address {
    text-transform: capitalize;
}

.item-footer {
    font-weight: 550;
}