.main-container {
    position: relative;
    min-height: 100%;
    height: 100%;
    background-color: white;
    /*f8f9fa*/
    width: 100%;
}

.main-container-content {
    /* min-height: 100%; */
    position: absolute;
    min-height: 100%;
    height: 100%;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
    padding: 10px;
    width: 100%;
    overflow-y: auto;
}

.logged-out-bg {
    background: url("../../../public/background.jpg") no-repeat;
    background-size: cover;
    /* background-image: linear-gradient( #3076C7, #5FD1F9) !important; */
}
.logged-in-bg {
    background-image: linear-gradient( #E2EAF1, #E2EAF1);
}

.container-div-with-x-y-scroll {
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.container-div-with-x-scroll {
    max-height: 100%;
    overflow-x: hidden;
}

.container-div-with-y-scroll {
    max-height: 100%;
    overflow-y: scroll;
}

.container-div-without-scroll {
    max-height: 100%;
}

.user-list-header-container {}

.user-list-data-container {
    padding-top: 10px;
    padding-bottom: 3rem;
}