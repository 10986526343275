
.option-country-flag{
    font-size: 25px;
    color: #111; font-weight: bold; letter-spacing: -1px; line-height: 1;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 15px;
    flex-shrink: 0;
}
