.container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 200px;

}

.container-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

