#profile-container-navigation {
    position: relative;
    bottom: 0px;
}

#profile-container-navigation .profile-nav-item-right {
    margin-right: -10px !important;
}

#profile-container-navigation .profile-nav-item {
    color: white;
    margin-bottom: -10px;
    margin-right: 25px;
    font-weight: 500;
    font-size: 14px;
    position: relative;
}

#profile-container-navigation .profile-nav-link {
    color: white;
}

#profile-container-navigation .profile-arrow-wrapper {
    position: absolute;
    bottom: 0px;
    margin-bottom: -30px;
    left: 50%;
}

#profile-container-navigation .profile-arrow-up {
    position: relative;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-bottom: 15px solid #E2EAF1;
    border-right: 20px solid transparent;
    left: -50%;
}
#container-header #profile-header {
  display: flex;
  justify-content: space-between;
}

#container-header #profile-header #user-data {
  display: flex;
  flex-direction: column;
}

#container-header #profile-header #profile-name {
  font-size: 24px;
}

#container-header #profile-header #profile-mail {
  font-size: 14px;
}