.my-contact-card {
    width: 250px;
    flex-shrink: 0;
    height: 265px;
    border-top-left-radius: 19px !important;
    border-top-right-radius: 19px !important;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    box-shadow: 3px 3px 3px #c5c5c5;
    display: flex;
}

.contact-title {
    width: 191px;
    height: 44px;
    color: #212121;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
}

.contact-title:hover {
    color: #1f52a0;
    text-decoration: none;
}


.contact-email {
    width: 226px;
    height: 22px;
    color: #0b40ff;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.contact-inbound{
    width: 226px;
    height: 18px;
    color: #000000;
    display: block;
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
}

.contact-outbound{
    width: 226px;
    height: 18px;
    color: #000000;
    display: block;
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
}
