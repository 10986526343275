.react-grid-layout {
    position: relative;
    transition: height 200ms ease;
}
.react-grid-item {
    background-color: #fff;
    transition: all 200ms ease;
    transition-property: left, top;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 5px;
}
.react-grid-item.cssTransforms {
    transition-property: transform;
}
.react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}

.react-grid-item.dropping {
    visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
    background: #777777;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
    display: none;
}

.remove {
    position: absolute;
    right: 7px;
    top: 0;
    cursor: pointer;
}

.widget-icon {
    padding-top: 1%;
}

/* widget styles */
.followed-company-name {
    color: #898989;
    font-weight: 500;
    font-size: 20px;
}

.followed-company-name.sm {
    font-size: 16px;
}

.followed-company-name:hover {
    color: #666666;
    text-decoration: none !important;
}

