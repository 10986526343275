.top-data {
    display: flex;
    justify-content: space-between;
}

.top-data .text {
    border: 1px solid;
    background-color:#409DEA;
    font-weight: 500;
    font-size: 18px;
    color: white;
    padding: 2px 4px 2px 4px;
}

.top-data .count {
    display: flex;
    justify-content: center;
    border: 1px solid;
    background-color: #409DEA;
    color: white;
    padding: 2px 4px 2px 4px;
    font-weight: 700;
    font-size: 18px;
    min-width: 30px;
}