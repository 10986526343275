#language-selector {
    color: #297BD2;
}

#language-selector :hover {
    cursor: pointer;
    color: #2959af;
}

.language-label {
    padding-left: 15px;
    padding-top: 10px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #929292;
    transition: all 0.25s;
}
