.search-not-found {
    margin: auto;
    font-size: 25px;
}

.search-item {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.25rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    min-height: 125px;
    margin-bottom: 15px;
}

.search-item .search-company-logo {
    height: 60px;
    width: 60px;
    background-color: white;
    background-clip: border-box;
    text-align: center;
    margin: 1em 0;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;

}

.search-item .search-company-logo .search-company-logo-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;

}

.search-item .search-company-logo img {
    /* display: inline; */
    /* margin: auto auto; */
    /* height: 100%; */
    vertical-align: middle;
    max-width: 100%;
}

.search-item .search-company-name {
    font-size: 26px;
    color: #111;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 1;
}

.search-item .search-company-country {
    font-size: 20px;
    color: #111;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 1;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 15px;
    flex-shrink: 0;
}

.search-item .search-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 25px;
}

.search-item .search-content .search-properties {
    display: flex;
}

.search-item .search-content .property {
    display: flex;
    justify-content: space-between;
    background-color: #EFEFEF;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.5rem;
    min-height: 25px;
    padding: 1px 4px 1px 4px;
    margin-right: 10px;
    margin-top: 5px;
}

.search-item .search-content .property .icon {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
}

.search-item .search-content .property .text {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    color: black;
}

.search-item .search-content .search-company-intro {
    padding-top: 5px;
    width:90%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    background:#fff;
}