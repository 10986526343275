#page-content-wrapper {
  min-width: 0;
  width: 100%;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  background-color: #E2EAF1;
}

#page-content-wrapper-not-logged {
  min-width: 0;
  width: 100%;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  background-image:
    linear-gradient(
      #3076C7, #5FD1F9
    );
}

#main-navbar-row {
  margin-bottom: 5px;
  background-color: white;
  box-shadow: 0px -10px 10px 5px black;

}

#sidebar-wrapper, #sidebar-wrapper-collapsed {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: 0px;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  height: 100%;
}

#sidebar-wrapper {
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
}

#sidebar-wrapper-collapsed {
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
}

html {
  height: 100vh;
}

body {
  margin: 0;
  height: 100vh;
  background-color: #D6D6D6;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #1B2430;
}


.aside-fixed+.content {
  margin-left: 240px;
}

.aside-fixed+.content-minimized {
  margin-left: 60px;
}

.aside-fixed+.content-overlay {
  margin-left: 0px;
  z-index: 9999;
}

.aside-fixed+.content-overlay-expanded {
  margin-left: 0px;
  filter: brightness(50%);
}
/* @media (min-width: 992px) {
  .aside-fixed+.content {
    margin-left: 60px;
  }
}

@media (min-width: 1200px) {
  .aside-fixed+.content {
    margin-left: 240px;
  }
} */

/* .content {
  padding: 20px;
}

@media (min-width: 992px) {
  .content {
    padding: 25px;
  }
}

@media (min-width: 1200px) {
  .content {
    padding: 30px;
  }
} */

.ht-100v {
  height: 100vh;
}

.aside-fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
}

.aside {
  display: block;
  width: 240px;
  height: 100%;
  transform: translateX(0%);
}

/* @media (min-width: 992px) {
  .aside-fixed {
    width: 60px;
  }
}

@media (min-width: 1200px) {
  .aside-fixed {
    width: 240px;
  }
} */

.aside.minimize {
  width: 60px;
}

.aside.remove {
  transform: translateX(-100%);
}

@media (max-width: 1800px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  #container-header-navigation {
    display: none;
  }
}

#container-header {
  position: relative;
  background: rgb(54, 123, 218);
  background: linear-gradient(90deg, rgba(54, 123, 218, 1) 0%, rgba(72, 186, 247, 1) 100%);
  width: 100%;
  color: white;
  min-height: 150px;
  padding: 25px;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.35rem;
}

#company-summary-container {
  display: inline-flex;
  align-items: center;
}

#container-header-navigation {
  position: absolute;
  bottom: 0px;
  margin-bottom: 15px;
}

#container-header-navigation .nav-item {
  color: white;
  margin-top: 10px;
  margin-right: 25px;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  text-decoration: none !important;
}

#container-header-navigation .nav-link {
  color: white;
}

#container-header-navigation .arrow-wrapper {
  position: absolute;
  bottom: 0px;
  margin-bottom: -20px;
  left: 50%;
}

#container-header-navigation .arrow-up {
  position: relative;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-bottom: 15px solid #E2EAF1;
  border-right: 20px solid transparent;
  left: -50%;
}


@media (min-width: 992px) {
  .aside {
    transform: translateX(0);
  }
}

/* Our custom components */
.iq-button-group {
  width: max-content !important;
}
.iq-button-group button {
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 25px;
  height: 39px;
  font-size: 14px;
  font-weight: bold;
  background-color: white;
  color: #4B4B4B;
  border-width: 1px;
  border-color: #DCDCDC !important;
  border: solid;
  outline: none !important;

  float: left;
  cursor: pointer;
}

.iq-button-group button.selected-inverted {
  border-color: #206fc3 !important;
  color: #206fc3;
}

.iq-button-group button.selected {
  border-color: #206fc3 !important;
  background-color: #206fc3;
  color: white;
}

.iq-button-group button.disabled {
  background-color: #d6d6d6;
  color: black;
}

.iq-button-group button:not(:last-child) {
  border-right: 2px solid;
  border-width: 2px;
  /* Prevent double borders */
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}


.iq-button-group button:not(:first-child) {
  border-left: 2px solid;
  border-width: 2px;
  /* Prevent double borders */
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/* .button-group button:not(.selected):not(:first-child) {
  border-left: none;
} */


.iq-button-group::after {
  content: "";
  clear: both;
  display: table;
}

/* buttons */
.iq-button {
  height: 40px !important;
  border-radius: 21px !important;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  border-style: none;
  font-family: Avenir-Book, sans-serif;
}

.iq-button:hover {
  text-decoration: none;
}

.iq-button-primary {
  background-color: #206fc3;
  color: #FFF !important;
}

.iq-button-primary:hover {
  background-color: #1f52a0;
  color: #FFF;
  text-decoration: none;
}
.iq-button-primary:disabled {
  background-color: #206fc3;
  color: #FFF;
  opacity: 0.5;
  cursor: not-allowed;
}

.iq-button-primary:hover:disabled {
  background-color: #206fc3;
  color: #FFF;
  opacity: 0.5;
  cursor: not-allowed;
}

.iq-button-secondary {
  background-color: #FFF;
  border: 2px solid #898989;
  padding: 6px;
  padding-left: 15px;
  padding-right: 15px;
  color: #898989 !important;
}

.iq-button-secondary:hover {
  background-color: #FFF;
  border: 2px solid #6d6d6d;
  color: #6D6D6D !important;
}

.iq-button-green {
  background-color: #FFF;
  border: 2px solid#65CDB5;
  color: #65CDB5 !important;
}

.iq-button-green:hover {
  background-color: #FFF;
  border: 2px solid #30C4A2;
  color: #30C4A2 !important;
}

.iq-button-orange {
  background-color: #FFF;
  border: 2px solid #F79C7A;
  color: #F79C7A !important;
}

.iq-button-orange:hover {
  background-color: #FFF;
  border: 2px solid #FF7E4E;
  color: #FF7E4E !important;
}

.iq-button-gradient {
  background-image: linear-gradient(90deg, rgba(54, 123, 218, 1) 0%, rgba(72, 186, 247, 1) 100%);
  color: #FFF !important;
}

.iq-button-gradient:hover {
  background-image: linear-gradient(90deg, #1f52a0 0%, rgb(66, 133, 190) 100%);
  color: #c5c5c5 !important;
  text-decoration: none;
}

.iq-button-tertiary {
  background-color: #52caf9;
  color: #FFF !important;
}

.iq-button-tertiary:hover {
  background-color: #4aaad6;
  color: #FFF !important;
  text-decoration: none;
}

div {
  font-family: Avenir-Book, sans-serif;
}

p {
  font-family: Avenir-Book, sans-serif;
}

.iqx-button {
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 25px;
  border-width: 0px;
  text-align: center;
  font-family: Avenir-Book, sans-serif;
}

.iqx-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.iqx-button-iconic {
  width: 52px;
  border-radius: 50%;
  border-width: 0px;
  text-align: center;
}

.iqx-button-iconic.lg-size {
  height: 52px;
  width: 52px;
  font-size: 20px;
}

.iqx-button-iconic.md-size {
  height: 42px;
  width: 42px;
  font-size: 16px;
}

.iqx-button-iconic.sm-size {
  height: 33px;
  width: 33px;
  font-size: 11px;
}

.iqx-button.lg-size {
  height: 52px;
  font-size: 18px;
  font-weight: bold;
}

.iqx-button.md-size {
  height: 42px;
  font-size: 16px;
  font-weight: bold;
}

.iqx-button.sm-size {
  height: 33px;
  font-size: 14px;
  font-weight: bold;
}

.iqx-button.primary {
  background-color: #206fc3;
  color: white;
}


.iqx-button-green {
  background-color: #FFF;
  border: 2px solid#65CDB5;
  color: #65CDB5;
}

.iqx-button-green:hover {
  background-color: #FFF;
  border: 2px solid #30C4A2;
  color: #30C4A2;
}

.iqx-button-orange {
  background-color: #FFF;
  border: 2px solid #F79C7A;
  color: #F79C7A;
}

.iqx-button-orange:hover {
  background-color: #FFF;
  border: 2px solid #FF7E4E;
  color: #FF7E4E;
}

.dark-blue {
  background-color: #206fc3;
  color: white;
}

.inverted {
  background-color: white;
  color: #206fc3;
  border-width: 2px;
  padding: -2px;
  border-color: #206fc3;
  border: solid;
}

.iq-link {
  color: #f2863b !important;
  font-weight: 500;
  text-decoration: none !important;
}

.iq-link:hover {
  color: #cf6a38 !important;
  font-weight: 500;
  text-decoration: none !important;
}

.iq-action-button {
  position: relative;
  margin-right: 0px;
  height: 40px;
  font-size: 13px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 25px;
  border-width: 0px;
  border: 2px solid #ffffff;
  color: #212121;
  outline: none !important;
  width: 150px;
  min-width: auto;
  max-width: 180px;
  font-weight: 500;
}

.iq-action-button:disabled {
  position: relative;
  margin-right: 0px;
  height: 40px;
  font-size: 13px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 25px;
  border-width: 0px;
  border: 2px solid #ffffff;
  color: #212121;
  outline: none !important;
  width: 150px;
  min-width: auto;
  max-width: 180px;
  font-weight: 500;
  opacity: 0.4;
  cursor: not-allowed;
}

/*.iq-action-button {
  position: relative;
  margin-right: 10px;
  height: 40px;
  font-size: 13px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 25px;
  border-width: 0px;
  border: 2px solid #ffffff;
  color: #212121;
  outline: none !important;
  width: 180px;
  font-weight: 500;
}

.iq-action-button:disabled {
  position: relative;
  margin-right: 10px;
  height: 40px;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 25px;
  border-width: 0px;
  border: 2px solid #ffffff;
  color: #212121;
  outline: none !important;
  width: 180px;
  font-weight: 500;
  opacity: 0.4;
  cursor: not-allowed;
}*/

.iq-action-button.white {
  background-color: white;
}

.iq-action-button.grey{
  background-color: #E2EAF1;
}

.iq-action-button.white:hover:not(:disabled) {
  background-color: white;
  border: 2px solid #3C8FE3;
}

.iq-action-button.grey:hover:not(:disabled) {
  background-color: #d4d8db;
  border: 2px solid #d4d8db;
}


.iq-action-button:disabled {
  opacity: 50%;
  cursor: not-allowed;
}

@media (max-width: 650px) {
  .iq-action-button {
    width: 100%;
  }
  #company-actions {
    margin-top: 25px !important;
    width: 100%;
  }
}

.iq-action-button .icon {
  position: absolute;
  right: 7%;
  top: 30%;
  /* margin-left: 15px; */
}

.iq-action-button .text {
  float: left;
}

.lang-select-flag {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border: 1px solid #2F70C4;
}

.lang-select-flag:hover {
  cursor: pointer;
  border: 1px solid #0062cc;
}

.lang-select:hover > .overlay {
  position:absolute;
  background-color:#000;
  opacity:0.35;
  border-radius:30px;
  height: 35px;
  width: 35px;
}

.iqx-link {
  color: #206fc3 !important;
  transition: all 0.25s !important;
  text-decoration: none;
}

.iqx-link:hover {
  color: #1f52a0 !important;
  text-decoration: none !important;
  cursor: pointer;
}

.sort-button {
  padding-left: 10px;
  color: #206fc3 !important;
}

.sort-button:hover {
  color: #1f52a0 !important;
}

.iq-headline {
  padding-left: 15px;
  padding-right: 15px;
  height: 50px;
  background-color: #2f6fc3;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.35rem;
  color: white;
  font-family: Avenir-Book, sans-serif;
}

.iq-headline .text {
  margin-right: auto;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.iqblade_fc_frame {
  bottom: 15px !important;
  left: 200px !important;
}

@media (max-width: 1200px) {
  .iqblade_fc_frame {
    bottom: 15px !important;
    left: 5px !important;
  }
}

@media (max-width: 992px) {
  .iqblade_fc_frame {
    display: none;
  }
}

.accept-cookies {
  z-index: 99999999;
  position: absolute;
  bottom: 0em;
  height: auto;
  padding: 1.0em;
  display: flex;
  justify-content: space-around;
  background-color: white;
  font-family: Roboto;
  width: 100%;
  align-items: center;
}

.accept-cookies .actions {
  display: flex;
  justify-content: space-around;
  flex-shrink: 0;
  gap: 0.2em;
}

.accept-cookies .text {
  padding: 0.5em;
}

.accept-cookies .actions button {
  height: 1;
}

/* Dropdown Button */
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
