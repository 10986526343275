.contact-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

.contact-card-label {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #929292;
    margin-bottom: 5px;
    transition: all 0.25s;

}

.contact-graph-label {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #929292;
    margin-bottom: 5px;
    transition: all 0.25s;
}

.nylas-contact-graph {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    padding-top: 50px;
    padding-bottom: 20px;
    height: 800px;
    width: 100%;
}


.contact-graph-text {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 300;
    height: auto;
}


.nylas-stats-card{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 100%;
}

#nylas-stats-card-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

#common-contact-card-container{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.nylas-stats-label{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #929292;
    margin-bottom: 5px;
    transition: all 0.25s;
}
.message-email-count-card{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 100%;
}


.nylas-common-contacts-container{
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    width: 100%;
    flex-wrap: wrap;
}

.nylas-common-profile{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    width: 50%;
    justify-content: space-evenly;
}
.nylas-common-stats{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
}
.nylas-common-profile-image{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nylas-common-outer-container{
    display: flex;
    flex-direction: column;
    width: 50%;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
}

#chartdiv {
    width: 100%;
    height: 100%;
}

#mycontact-container-header {
    background: rgb(54, 123, 218);
    background: linear-gradient(90deg, rgba(54, 123, 218, 1) 0%, rgba(72, 186, 247, 1) 100%);
    width: 100%;
    color: white;
    min-height: 150px;
    padding: 25px;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    display: flex;
}