.events-stats-label{
    display: flex;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #929292;
    margin-bottom: 5px;
    transition: all 0.25s;
    margin-left: auto;
    flex-wrap: wrap;
}