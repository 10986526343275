#campaign-list-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: flex-start;
    /* align-content: flex-start; */
    margin-top: 15px;
}
  
  /* .adv-search-card-container {
      box-sizing: border-box;
      width: 800px;
      min-width: 350px;
      padding: 15px;
      flex-shrink: 1;
  } */
  
  .campaign-card {
    position: relative;
    display: flex;
    width: 700px;
    justify-content: space-between;
    min-width: 350px;
    flex-direction: column;
    background-color: white;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.55rem !important;
    padding: 10px 15px 0px 15px;
    /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.12); */
    transition: box-shadow 0.5s;
    flex-shrink: 1;
    flex-grow: 1;
    animation: fadein 1s;
  }
  
  @media (min-width: 1700px) {
    .campaign-card {
      width: 750px;
      flex-grow: 0;
      margin: 15px;
    }
    #campaign-list-container {
      justify-content: space-between;
    }
  }
  
  @media (max-width: 1700px) {
    .campaign-card {
      max-width: auto;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  .campaign-card:hover {
    box-shadow: 0 0 2px 2px #93c1f2;
    transition: box-shadow 0.5s;
  }
  
  .campaign-magnifier-box {
    position: relative;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.55rem;
    height: 35px;
    width: 35px;
    background-color: #3078c7;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .campaign-card .name {
    font-family: Avenir-Book, sans-serif;
    position: relative;
    margin-left: 12px;
    top: 2px;
    font-size: 22px;
    font-weight: 600;
    background-color: white;
    color: #3078c7;
    text-transform: uppercase;
    border-radius: 0.35rem;
  }
  
  .campaign-content-container {
    position: relative;
    margin-top: 7px;
    margin-left: -5px;
  }
  
  .campaign-content {
    display: flex;
    flex-direction: column;
  }
  
  .campaign-details-container {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;
  }
  
  .campaign-detail-container {
    display: flex;
    justify-content: space-around;
  }
  
  .campaign-detail {
    /* font-weight: bold; */
    padding: 4px;
    margin: 5px;
    background-clip: border-box;
    /* border: 1px solid rgba(72, 94, 144, 0.16); */
    border-radius: 0.35rem;
    color: black;
    background-color: #efefef;
  }
  
  .campaign-actions {
    display: flex;
    justify-content: flex-end;
    align-content: space-around;
    flex-wrap: wrap;
    margin-top: 15px;
  }
  
  .campaign-action {
    margin: 5px;
  }
  
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  