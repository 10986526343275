.profile-welcome {
    font-size: 25px;
    font-weight: 700;
    letter-spacing: .5px;
    color: #206fc3;
    margin-bottom: 5px;
    transition: all 0.25s;
}

.profile-credit-reset {
    font-size: 12px;
    color: #929292;
}

.profile-header-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
}

.profile-avatar {
    height: 70px;
    width: 70px;
    object-fit: cover;
    box-shadow: 0 0 4pt 1pt  #206fc3;
}