
@media (max-width: 650px) {
    #company-actions {
        margin-top: 25px !important;
        width: 100%;
    }
  }

.company-logo {
    height: 75px;
    width: 75px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.25rem;
    border-radius: 50%;
    min-width: 75px;
    overflow: hidden;
}

.company-logo .company-logo-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.organisation-button-text{
    display: table;
    margin: auto;
}

.company-logo img {
    display: inline; 
    margin: auto auto;
    height: 100%; 
    vertical-align: middle;
    max-width: 100%;
}

.featured-on {
    font-size: 18px !important;
    font-weight: 500;
    background-color: white;
    color: #1F52A0;
    border-radius: 0.35rem;
}

.featured-on-body {
    padding: 0.85rem !important;
}

@media (min-width: 1200px) {
    /* CSS that should be displayed if width is equal to or less than 800px goes here */
    .company-logo {
      display: inline-block;
    }
    /* #company-summary {
      width: 50%;
    } */
  }

  @media (max-width: 1200px) {
    /* CSS that should be displayed if width is equal to or less than 800px goes here */
    .company-logo {
      display: block;
      /* margin-left: auto;
      margin-right: auto; */
      margin-bottom: 15px;
    }
  }
