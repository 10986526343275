.yt-logo {
    position: absolute;
    display: block;
    top: 20px;
    left: 60px;
}

.yt-banner{
    overflow:hidden;
    width:100%;
    height:120px;
    margin:0 auto 30px auto;
}

.yt-banner img{
    height: 120px;
    width: 100%;
    object-fit: cover
}