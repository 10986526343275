.title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.7px;
    color:  #2f6fc3;
    margin-bottom: 5px;
    transition: all 0.25s;
}

.project-info {
    display: flex;
    flex-direction: row;
    gap: 2%;
}

.info-entry {
    display: flex;
    flex-direction: column;
    gap: 1%;
}

.w-33 {
    width: 33%;
}

.header {
    font-weight: 550;
}

.badge-tag {
    margin-right: 10px;
    font-size: 14px !important;
    padding: 7px !important;
    background-color: #3a9fdf !important;
    margin-bottom: 5px;
}