.profile-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    height: 100%;
}

.profile-card .title {
    display: flex;
    gap: 1em;
    align-items: center;
    margin-bottom: 0.5em;
}
