.dashboard-item-container {
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: "hidden";
  padding-top: 20px;
}

.react-grid-item {
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.35rem;
}

.react-grid-item:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.react-grid-item:active {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.dashboard-item-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  margin-right: -10px;
  background-color: white;
  border-radius: 50%;
  color:  #3378d1;
}

.dashboard-item-icon {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: -45px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: #3378d1;
  border-radius: 1rem;
  border-color: white;
  border: 5px solid;
  color: white;
}

.dashboard-youtube-video-list {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
