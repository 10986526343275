.contract-title-label {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #333;
    margin-bottom: 5px;
    transition: all 0.25s;
}

.title {
    font-weight: bold;
    text-transform: uppercase;
}

.subtitle {
    color: #898989;
    white-space: nowrap;
}

.follow-button {
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.follow-contract-spinner {
    display: block;
    marginLeft: "auto";
    marginRight: "auto";
    height: 0.5rem;
    width: 0.5rem;
}
