#managed-campaign-view-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    border-radius: 0.2em;
    padding: 2.5em;
    gap: 1em;
}

#managed-campaign-view-container #campaign-title {
    font-weight: 500;
    font-size: 1.5em;
    background-color: #3078C7;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

#managed-campaign-view-container #campaign-status {
    font-weight: 500;
    font-size: 1.0em;
    background-color: gray;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

#managed-campaign-view-container #campaign-personas{
    display: flex;
    gap: 0.4em;
}

#managed-campaign-view-container #campaign-personas .persona {
    font-weight: 500;
    font-size: 0.8em;
    background-color: gray;
    color: white;
    padding: 7px;
    border-radius: 10px;
}

#managed-campaign-view-container #campaign-headline {
    font-weight: 500;
    font-size: 1.0em;
    background-color: whitesmoke;
    color: black;
    padding: 10px;
    border-radius: 10px;
}

#managed-campaign-view-container #campaign-parties {
    display: flex;
    gap: 2em;
    
}


#managed-campaign-view-container #campaign-parties .party {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    background-color: orange;
    padding: 1.0em;
    border-radius: 10px;
}

#managed-campaign-view-container #campaign-parties .pink {
    background-color: #F84899;
}
#managed-campaign-view-container #campaign-parties .green {
    background-color: #30C4A2;
}
#managed-campaign-view-container #campaign-parties .orange {
    background-color:  #FF7E4E;
}
#managed-campaign-view-container #campaign-parties .purple {
    background-color: purple;
}

#managed-campaign-view-container #campaign-parties .party .identity{
    display: flex;
    gap: 1em;
    align-items: center;
}

#managed-campaign-view-container #campaign-parties .party .type{
    font-weight: 500;
    font-size: 1.2em;
    color: white;
}

/* #managed-campaign-view-container #campaign-parties .party .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: black;
} */

#managed-campaign-view-container #campaign-parties .party .name{
    font-weight: 700;
    color: white;
}



#managed-campaign-view-container #campaign-users {
    display: flex;
    gap: 2em;
    
}


#managed-campaign-view-container #campaign-users .user {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    background-color: orange;
    padding: 0.5em;
    border-radius: 10px;
}

#managed-campaign-view-container #campaign-users .pink {
    background-color: #F84899;
}
#managed-campaign-view-container #campaign-users .green {
    background-color: #30C4A2;
}
#managed-campaign-view-container #campaign-users .orange {
    background-color:  #FF7E4E;
}

#managed-campaign-view-container #campaign-users .user .identity{
    display: flex;
    gap: 1em;
    align-items: center;
}

/* #managed-campaign-view-container #campaign-users .user .logo{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: black;
} */

#managed-campaign-view-container #campaign-users .user .name{
    font-weight: 700;
    color: white;
}


#managed-campaign-view-container #campaign-stages {
    display: flex;
    gap: 3em;
}

#managed-campaign-view-container #campaign-stages .stage {
    position: relative;
    color: white;
    background-color: #3078C7;
    font-weight: 500;
    /* margin-bottom: 5px; */
    padding: 12px;
    cursor: pointer;
}

/* #managed-campaign-view-container #campaign-stages .stage:not(:last-child) {
    position:absolute;
    background: 
    linear-gradient(to top left,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) calc(50% - 0.8px),
        rgba(0,0,0,1) 50%,
        rgba(0,0,0,0) calc(50% + 0.8px),
        rgba(0,0,0,0) 100%),
    linear-gradient(to top right,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0) calc(50% - 0.8px),
        rgba(0,0,0,1) 50%,
        rgba(0,0,0,0) calc(50% + 0.8px),
        rgba(0,0,0,0) 100%);
} */

#managed-campaign-view-container #campaign-stages .stage:last-child {
    background-color: #808080;
    cursor: default;
}

#managed-campaign-view-container #campaign-stages .end-stage:last-child {
    border-color: transparent transparent transparent  #808080;
}


#managed-campaign-view-container #campaign-stages .end-stage:not(:last-child):after {
    content: '';
    height: 0;
    display: block;
    border-color: transparent transparent transparent  #3078C7;
    ;
    border-width: 1.5em;
    margin-right: -3em;
    border-style: solid;
    position: absolute;
    top: 0;
    right: 0;
    /* left:s; */
    /* left: 180px; */
}
