#campaign-title {
    font-weight: 500;
    font-size: 24px;
    background-color: #3078C7;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

.campaign-sub-title {
    font-weight: 500;
    font-size: 24px;
    background-color: white;
    color: #3078C7;
    padding: 10px;
    border-radius: 10px;
}

.statistic-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: 0.35rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    height: 100%;
  }

.statistic-card .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.7px;
    color: #929292;
    margin-bottom: 5px;
    transition: all 0.25s;
}

.statistic-card .value {
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 1.5px;
}
