.news-items {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 0;
  flex-wrap: wrap;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(72, 94, 144, 0.16);
  border-radius: 0.25rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 85px;
  margin-bottom: 15px;
  margin-top: 5px;
  padding: 15px;
}

.news-items .news-company-name {
    display: flex;
    position: relative;
    width: 100%;
    margin-right: -15px;
    float: right;
    /* margin-bottom: -15px; */
    background-color: #3078C7;
    bottom: 0px;
    min-height: 5px;;
    font-weight: 500;
    text-transform: uppercase;
    justify-content: center;
    color: white;
}

.news-items .news-company-logo {
  height: 50px;
  width: 50px;
  background-color: white;
  background-clip: border-box;
  border: 2px solid rgba(72, 94, 144, 0.16);
  border-radius: 50%;
  text-align: center;
  margin: 1em 0;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  position: relative;
  margin-right: 5px;
  cursor: pointer;
}

.news-items .news-company-logo:hover {
    border: 2px solid#3078C7;
}
.news-items .news-company-logo .news-company-logo-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.news-items .news-company-logo img {
  /* display: inline; */
  /* margin: auto auto; */
  /* height: 100%; */
  vertical-align: middle;
  max-width: 100%;
}

.news-company-logo p {
  /* display: inline; */
  /* margin: auto auto; */
  /* height: 100%; */
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}
