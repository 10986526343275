.social-link {
    color: #F2863B !important;
    padding: 5px;
}

.social-link:hover {
    opacity: 50%;
    color: #F2863B !important;
}

.officers-containter {
    margin-top: 2.5em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2em;
}
