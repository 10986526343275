#admin-analytics-container {
    display: flex;
    gap: 1em;
    flex-direction: column;
    background-color: white;
    padding: 0.4em;
    justify-content: flex-start;
}

#admin-analytics-container .row { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

#admin-analytics-container .row .card {
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    min-width: 25em;
    max-width: 100%;
    padding: 0.4em;
    width: auto;
    flex: 50%;
}

#admin-analytics-container .row .card .header {
    display: flex;
    gap: 0.4em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#admin-analytics-container .row .card .title {

}

#admin-analytics-container .row .card .switcher {

}

